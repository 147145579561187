<template>
  <CRow class="form-group">
    <CCol lg="9" md="9">
  <div class="ms-point-staking-setting-container">
    <CCard>
      <CCardBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <CForm @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="form-group">
              <CCol lg="10" md="10">
                <div class="description" v-html="$t('MS_POINT_STAKING_SETTING_COMPONENT_DESCRIPTION')"></div>
              </CCol>
              <CCol lg="2" md="2">
                <ValidationProvider name="MS_POINT_STAKING_SETTING_COMPONENT_IS_ENABLED_FIELD" rules="required">
                  <div class="text-right" slot-scope="{ errors }">
                    <div class="btn-switch-wrapper">
                      <label class="btn-switch" :class="[{'btn-switch-ja': locale == 'ja'}]">
                        <input type="checkbox" class="checkbox" v-model="isStakingIsEnabled" />
                        <span class="check-silder"></span>
                      </label>
                    </div>
                    <span class="error-msg-wrap">
                      <span class="error-msg">{{ errors[0] }}</span>
                    </span>
                  </div>
                </ValidationProvider>
              </CCol>
            </CRow>

            <h2>{{ $t('MS_POINT_STAKING_SETTING_COMPONENT_TITLE_MEMBERSHIP') }}</h2>
            <CRow class="form-group form-paid-membership">
              <CCol lg="12" md="12">
                <table class="table table-striped table-price">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t('MS_POINT_STAKING_SETTING_COMPONENT_COLUMN_NAME') }}</th>
                      <th scope="col">{{ $t('MS_POINT_STAKING_SETTING_COMPONENT_COLUMN_POINTS') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in membershipTypes" :key="index">
                      <td width="200px">
                        <div class="form-control-plaintext">
                          <StakingBadge class="no-border" type="membership-type" :text="item.name" />
                        </div>
                      </td>
                      <td>
                        <ValidationProvider
                          :name="$t(`MS_POINT_STAKING_SETTING_COMPONENT_FIELD_POINTS`, { name: item.name })"
                          rules="required|min_value:1"
                        >
                          <div class="form-group group-price" slot-scope="{ errors }" :key="index">
                            <currency-input class="input-field form-control text-right" v-model="item.staking_points" :precision="0" />
                            <span class="currency-name">Point</span>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CCol>
            </CRow>

            <CRow class="form-actions mt-3" v-if="isAllowedUpdating">
              <CCol lg="12" md="12">
                <CButton v-if="isAllowedUpdating" color="primary" class="btn btn-primary btn-save" @click="onSubmit">{{
                  $t('MS_POINT_STAKING_SETTING_COMPONENT_BUTTON_SAVE')
                }}</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
      </CCol>
</CRow>

</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { Constants } from '@/constants';
import stringHelper from '@/utils/string-helper';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import StakingBadge from '@/components/StakingBadge';

export default {
  name: 'MSPointStakingSetting',
  components: {
    StakingBadge,
  },
  data() {
    return {
      isStakingIsEnabled: false,
      delayTimeInSeconds: 0,
    };
  },
  props: {
    membershipTypes: {
      type: Array,
      default: () => [],
    },
    msPointStakingIsEnabled: {
      type: Boolean,
      default: () => null,
    },
    isAllowedUpdating: {
      type: Boolean,
      default: () => false,
    },
  },
  computed : {
    ...mapGetters(['locale']),
  },
  async mounted() {
    this.isStakingIsEnabled = this.msPointStakingIsEnabled;
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      this.updateStakingSetting();
    },
    async updateStakingSetting() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      try {
        const membershipTypes = this.membershipTypes.map((item) => {
          return {
            id: item.id,
            points: item.staking_points,
          };
        });
        const data = {
          ms_point_staking_is_enabled: this.isStakingIsEnabled,
          membership_types: membershipTypes,
        };
        const result = await this.$http.put(endpoints.updateStakingSetting, data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBERSHIP_TYPES_PAGE_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MS_POINT_SETTING_PAGE_TEXT_NOTIFY_SUCCESS'),
        });

        this.isProcessing = false;
      } catch (err) {
        this.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBERSHIP_TYPES_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.ms-point-staking-setting-container {
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;
  .card {
    border: none;
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }

    h2 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }

    .description {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: #8a93a2;
      strong {
        font-weight: bold;
      }
    }

    .btn-switch-wrapper {
      margin: 0;
      line-height: 27px;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }

    .error-msg-wrap {
      display: block;
    }

    .group-price {
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0;
      input {
        width: calc(100% - 60px);
        text-align: right;
      }
      span.currency-name {
        width: 50px;
        font-size: 14px;
        font-weight: 300;
        color: #4d5666;
        line-height: 35px;
        margin: 0;
        padding-left: 5px;
      }
      .error-msg-wrap {
        width: 100%;
      }
    }

    .form-paid-membership {
      .table-price {
        border: 1px solid #dadada;

        td {
          padding: 0.5rem 0.75rem;
          vertical-align: middle;
          border-top: 1px solid;
          border-top-color: #d8dbe0;
        }

        .form-group {
          input {
            font-size: 14px;
            font-weight: 500;
            color: #3a4a7f;
          }
        }
        .btn-switch-wrapper {
          height: 34px;
          padding-top: 8px;
        }
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 4px;
        width: 145px;
        height: 35px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-save {
          width: 50%;
        }
      }
    }
  }
}
</style>
