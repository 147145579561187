var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('div',{staticClass:"ms-point-claiming-setting-container"},[_c('CCard',[_c('CCardBody',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"10","md":"10"}},[_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.$t('MS_POINT_CLAIMING_SETTING_COMPONENT_DESCRIPTION'))}})]),_c('CCol',{attrs:{"lg":"2","md":"2"}},[_c('ValidationProvider',{attrs:{"name":"MS_POINT_CLAIMING_SETTING_COMPONENT_IS_ENABLED_FIELD","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{staticClass:"text-right"},[_c('div',{staticClass:"btn-switch-wrapper"},[_c('label',{staticClass:"btn-switch",class:[{'btn-switch-ja': _vm.locale == 'ja'}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isClaimmingIsEnabled),expression:"isClaimmingIsEnabled"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isClaimmingIsEnabled)?_vm._i(_vm.isClaimmingIsEnabled,null)>-1:(_vm.isClaimmingIsEnabled)},on:{"change":function($event){var $$a=_vm.isClaimmingIsEnabled,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isClaimmingIsEnabled=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isClaimmingIsEnabled=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isClaimmingIsEnabled=$$c}}}}),_c('span',{staticClass:"check-silder"})])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])}}],null,true)})],1)],1),_c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"3","md":"3"}}),_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('div',{staticClass:"delay-time-section"},[_c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"text-right",attrs:{"lg":"8","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"MS_POINT_CLAIMING_SETTING_COMPONENT_REPEAT_FIELD","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('MS_POINT_SETTING_PAGE_TIME_TO_CLAIM_LABEL')))]),_c('currency-input',{staticClass:"delay-time-input input-field form-control",attrs:{"precision":0},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)}}],null,true)})],1),_c('CCol',{attrs:{"lg":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"MS_POINT_CLAIMING_SETTING_COMPONENT_DURATION_FIELD","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.duration),expression:"duration"}],staticClass:"form-control duration-dropdown",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.duration=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t('MS_POINT_CLAIMING_SETTING_COMPONENT_CHOOSE_DURATION_LABEL')))]),_vm._l((_vm.durations),function(item){return _c('option',{key:item.value,domProps:{"value":item.value}},[_vm._v(_vm._s(item.label))])})],2),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])}}],null,true)})],1)],1)],1)])],1),_c('h2',[_vm._v(_vm._s(_vm.$t('MS_POINT_CLAIMING_SETTING_COMPONENT_TITLE_MEMBERSHIP')))]),_c('CRow',{staticClass:"form-group form-paid-membership"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[_c('table',{staticClass:"table table-striped table-price"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('MS_POINT_CLAIMING_SETTING_COMPONENT_COLUMN_NAME')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('MS_POINT_CLAIMING_SETTING_COMPONENT_COLUMN_POINTS')))])])]),_c('tbody',_vm._l((_vm.membershipTypes),function(item,index){return _c('tr',{key:index},[_c('td',{attrs:{"width":"150px"}},[_c('div',{staticClass:"form-control-plaintext"},[_c('StakingBadge',{staticClass:"no-border",attrs:{"type":"membership-type","text":item.name}})],1)]),_c('td',[_c('ValidationProvider',{attrs:{"name":_vm.$t("MS_POINT_CLAIMING_SETTING_COMPONENT_FIELD_POINTS", { name: item.name }),"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{key:index,staticClass:"form-group group-price"},[_c('currency-input',{staticClass:"input-field form-control text-right",attrs:{"precision":0},model:{value:(item.claim_points),callback:function ($$v) {_vm.$set(item, "claim_points", $$v)},expression:"item.claim_points"}}),_c('span',{staticClass:"currency-name"},[_vm._v("Point")]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)}}],null,true)})],1)])}),0)])])],1),(_vm.isAllowedUpdating)?_c('CRow',{staticClass:"form-actions mt-3"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[(_vm.isAllowedUpdating)?_c('CButton',{staticClass:"btn btn-primary btn-save",attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('MS_POINT_CLAIMING_SETTING_COMPONENT_BUTTON_SAVE')))]):_vm._e()],1)],1):_vm._e()],1)]}}])})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }