var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('div',{staticClass:"ms-point-exchange-setting-container"},[_c('CCard',[_c('CCardBody',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',{staticClass:"form-group"},[_c('CCol',{attrs:{"lg":"10","md":"10"}},[_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.$t('MS_POINT_EXCHANGE_SETTING_COMPONENT_DESCRIPTION'))}})]),_c('CCol',{attrs:{"lg":"2","md":"2"}},[_c('ValidationProvider',{attrs:{"name":"MS_POINT_EXCHANGE_SETTING_COMPONENT_IS_ENABLED_FIELD","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{staticClass:"text-right"},[_c('div',{staticClass:"btn-switch-wrapper"},[_c('label',{staticClass:"btn-switch",class:[{'btn-switch-ja': _vm.locale == 'ja'}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isExchangeEnabled),expression:"isExchangeEnabled"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isExchangeEnabled)?_vm._i(_vm.isExchangeEnabled,null)>-1:(_vm.isExchangeEnabled)},on:{"change":function($event){var $$a=_vm.isExchangeEnabled,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isExchangeEnabled=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isExchangeEnabled=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isExchangeEnabled=$$c}}}}),_c('span',{staticClass:"check-silder"})])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])}}],null,true)})],1)],1),_c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"text-right",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"min-value-section"},[_c('ValidationProvider',{attrs:{"name":"MS_POINT_EXCHANGE_SETTING_COMPONENT_MININUM_VALUE_FIELD_NAME","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('MS_POINT_EXCHANGE_SETTING_COMPONENT_MININUM_VALUE_LABEL')))]),_c('currency-input',{staticClass:"delay-time-input input-field form-control",attrs:{"precision":2},model:{value:(_vm.mininumValueInUsdt),callback:function ($$v) {_vm.mininumValueInUsdt=$$v},expression:"mininumValueInUsdt"}}),_c('span',{staticClass:"currency-name ml-2"},[_vm._v("USDT")]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)}}],null,true)})],1)])],1),_c('h2',[_vm._v(_vm._s(_vm.$t('MS_POINT_EXCHANGE_SETTING_COMPONENT_TITLE_MEMBERSHIP')))]),_c('CRow',{staticClass:"form-group form-paid-membership"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[_c('table',{staticClass:"table table-striped table-price"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('MS_POINT_EXCHANGE_SETTING_COMPONENT_COLUMN_NAME')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('MS_POINT_EXCHANGE_SETTING_COMPONENT_COLUMN_POINTS')))])])]),_c('tbody',_vm._l((_vm.membershipTypes),function(item,index){return _c('tr',{key:index},[_c('td',{attrs:{"width":"200px"}},[_c('div',{staticClass:"form-control-plaintext"},[_c('StakingBadge',{staticClass:"no-border",attrs:{"type":"membership-type","text":item.name}})],1)]),_c('td',[_c('ValidationProvider',{attrs:{"name":_vm.$t("MS_POINT_EXCHANGE_SETTING_COMPONENT_FIELD_POINTS", { name: item.name }),"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{key:index,staticClass:"form-group group-price"},[_c('currency-input',{staticClass:"input-field form-control text-right",attrs:{"precision":0},model:{value:(item.exchange_points),callback:function ($$v) {_vm.$set(item, "exchange_points", $$v)},expression:"item.exchange_points"}}),_c('span',{staticClass:"currency-name"},[_vm._v("Point")]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)}}],null,true)})],1)])}),0)])])],1),(_vm.isAllowedUpdating)?_c('CRow',{staticClass:"form-actions mt-3"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[(_vm.isAllowedUpdating)?_c('CButton',{staticClass:"btn btn-primary btn-save",attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('MS_POINT_EXCHANGE_SETTING_COMPONENT_BUTTON_SAVE')))]):_vm._e()],1)],1):_vm._e()],1)]}}])})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }