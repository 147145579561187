<template>
  <div class="update-ms-point-container wrapper">
    <CRow>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <h1>{{ $t('MS_POINT_SETTING_COMPONENT_TITLE_MS_POINT') }}</h1>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>
          <CCardBody v-if="!isLoading">

            <CTabs class="mt-3 ms-point-setting-tabs" variant="tabs" :active-tab="0">
              <CTab :title="$t('MS_POINT_SETTING_COMPONENT_TAB_QUIZ_SURVEY')">
                <Survey :msPointSurveyIsEnabled="msPointSurveyIsEnabled" />
              </CTab>
              <CTab :title="$t('MS_POINT_SETTING_COMPONENT_TAB_CLAIM')">
                <MSPointClaimingSetting
                  :membershipTypes="membershipTypes"
                  :msPointClaimingIsEnabled="msPointClaimingIsEnabled"
                  :msPointDelayTimeInSeconds="msPointDelayTimeInSeconds"
                  :msPointDelayTimeDuration="msPointDelayTimeDuration"
                  :isAllowedUpdating="isAllowedUpdating"
                />
              </CTab>

              <CTab :title="$t('MS_POINT_SETTING_COMPONENT_TAB_STAKING')">
                <MSPointStakingSetting
                  :membershipTypes="membershipTypes"
                  :msPointStakingIsEnabled="msPointStakingIsEnabled"
                  :isAllowedUpdating="isAllowedUpdating"
                />
              </CTab>

              <CTab :title="$t('MS_POINT_SETTING_COMPONENT_TAB_UPGRADE_MEMBERSHIP')">
                <MSPointUpgradingMembershipSetting
                  :membershipTypes="membershipTypes"
                  :msPointUpgradingMembershipIsEnabled="msPointUpgradingMembershipIsEnabled"
                  :isAllowedUpdating="isAllowedUpdating"
                />
              </CTab>

              <CTab :title="$t('MS_POINT_SETTING_COMPONENT_TAB_EXCHANGE')">
                <MSPointExchangeSetting
                  :membershipTypes="membershipTypes"
                  :msPointExchangeIsEnabled="msPointExchangeIsEnabled"
                  :msPointExchangeMininumValueInUsdt="msPointExchangeMininumValueInUsdt"
                  :isAllowedUpdating="isAllowedUpdating"
                />
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import endpoints from '@/constants/endpoints';
import { VclList } from 'vue-content-loading';
import { mapState, mapActions, mapGetters } from 'vuex';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import MSPointClaimingSetting from '@/components/MSPointSettings/MSPointClaimingSetting';
import MSPointUpgradingMembershipSetting from '@/components/MSPointSettings/MSPointUpgradingMembershipSetting';
import MSPointStakingSetting from '@/components/MSPointSettings/MSPointStakingSetting';
import MSPointExchangeSetting from '@/components/MSPointSettings/MSPointExchangeSetting';
import Survey from './../survey/SurveyList';

export default {
  name: 'MSPointSetting',
  components: {
    VclList,
    MSPointClaimingSetting,
    MSPointStakingSetting,
    MSPointUpgradingMembershipSetting,
    MSPointExchangeSetting,
    Survey,
  },
  data() {
    return {
      whiteSpace: '    ',
      isLoading: true,
      phases: [],
      membershipTypes: [],
      msPointMode: '',
      msPointClaimingIsEnabled: false,
      msPointDelayTimeInSeconds: null,
      msPointDelayTimeDuration: null,
      msPointStakingIsEnabled: false,
      msPointUpgradingMembershipIsEnabled: false,
      msPointExchangeIsEnabled: false,
      msPointExchangeMininumValueInUsdt: null,
      isAllowedUpdating: false,
      msPointSurveyIsEnabled: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    this.checkPermissions();
    await Promise.all([this.getMSPointPhases(), this.getMSPointSettings()]);
    this.isLoading = false;
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((item) => {
          if (item.name === 'UPDATE_MS_POINT_SETTINGS') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getMSPointSettings() {
      try {
        const res = await this.$http.get(endpoints.getMSPointSettings);

        this.membershipTypes = res.data.membership_types;
        this.msPointMode = res.data.ms_point_mode;
        this.msPointClaimingIsEnabled = res.data.ms_point_claiming_is_enabled;
        this.msPointDelayTimeInSeconds = res.data.ms_point_delay_time_in_seconds;
        this.msPointDelayTimeDuration = res.data.ms_point_delay_time_duration;
        this.msPointStakingIsEnabled = res.data.ms_point_staking_is_enabled;
        this.msPointUpgradingMembershipIsEnabled = res.data.ms_point_upgrading_membership_is_enabled;
        this.msPointExchangeIsEnabled = res.data.ms_point_exchange_is_enabled;
        this.msPointExchangeMininumValueInUsdt = res.data.ms_point_exchange_mininum_value_in_usdt;
        this.msPointSurveyIsEnabled = res.data.ms_point_survey_is_enabled;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MS_POINT_SETTING_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getMSPointPhases() {
      try {
        const result = await this.$http.get(endpoints.getMSPointPhases);
        this.phases = stringHelper.translateTextForDropdownList(result.data, 'MS_POINT_PHASE');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async updateModeSettings() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      try {
        const data = {
          ms_point_mode: this.msPointMode,
        };
        const result = await this.$http.put(endpoints.updateModeSettings, data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MS_POINT_SETTING_PAGE_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MS_POINT_SETTING_PAGE_TEXT_NOTIFY_SUCCESS'),
        });
        this.isProcessing = false;
      } catch (err) {
        this.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MS_POINT_SETTING_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then((isValid) => {
        if (!isValid) {
          return;
        }

        this.updateModeSettings();
      });
    },
  },
};
</script>
<style lang="scss">
.update-ms-point-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }

    h2 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }

    .update-mode-form {
      label {
        margin-bottom: 0;
      }
    }

    .col-form-label {
      // width: 100%;
      // text-align: right;
    }
    .col-form-unit {
      text-align: left;
      margin: 5px 0px;
    }
    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 4px;
        min-width: 145px;
        height: 35px;
        padding: 0 30px;
        color: #fff;

        &.btn-primary {
          background: #3b4b7e;
        }
      }
    }
  }

  .ms-point-setting-tabs {
    .nav.nav-tabs {
      border-bottom: none;
      .nav-item {
        .nav-link {
          background-color: transparent;
          font-style: normal;
          font-size: 14px;
          font-weight: bold;
          color: #8a93a2;
          &.active {
            color: #4d5666;
          }
        }
      }
    }
    .tab-content {
      .card {
        > .card-body {
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
